import axios from 'axios';

export const getAllCast = async () => {
    try {
        const response = await axios.get('https://api.bossteamaplikacija.rs/api/cast/');
        return response;
    } catch (error) {
        console.log(error)
    }
};

export const addNewUser = async (type: string, fullName: string, dateOfBirth: string, nickname: string, jmbg: number, address: string, city: string, height: number, weight: number, hairColor: string, hairSize: string, eyeColor: string, race: string, experienceDescription: string, yearsOFExperience: number, specificity: string,  contactPhone: string, emailAddress: string, additionalInfo: string, sex: string) => {
    try {
        const response = await axios.post('https://api.bossteamaplikacija.rs/api/cast/', {
            type: type,
            fullName: fullName,
            dateOfBirth: dateOfBirth,
            nickname: nickname,
            jmbg: jmbg,
            address: address,
            city: city,
            height: height,
            weight: weight,
            hairColor: hairColor,
            hairSize: hairSize,
            eyeColor: eyeColor,
            race: race,
            experienceDescription: experienceDescription,
            yearsOFExperience: yearsOFExperience,
            specificity: specificity,
            contactPhone: contactPhone,
            emailAddress: emailAddress,
            additionalInfo: additionalInfo,
            sex: sex,
        })
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const filterCast = async (dateOfBirthFrom: string, dateOfBirthTo: string, heightFrom: number, heightTo: number, weightFrom: number, weightTo: number, yearsOFExperienceFrom: number, yearsOFExperienceTo: number) => {
    try {
        const response = await axios.post('https://api.bossteamaplikacija.rs/api/cast/filter', {
            dateOfBirthFrom: dateOfBirthFrom,
            dateOfBirthTo: dateOfBirthTo,
            heightFrom: heightFrom,
            heightTo: heightTo,
            weightFrom: weightFrom,
            weightTo: weightTo,
            yearsOFExperienceFrom: yearsOFExperienceFrom,
            yearsOFExperienceTo: yearsOFExperienceTo, 
        })
        return response;
    } catch (error) {
        console.log(error);
    };
}

export const deleteCast = async (id: number) => {
    try {
        const response = await axios.post('https://api.bossteamaplikacija.rs/api/cast/delete', {
            id: id,
        })
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const editCast = async (id: number, type: string, fullName: string, dateOfBirth: string, nickname: string, jmbg: number, address: string, city: string, height: number, weight: number, hairColor: string, hairSize: string, eyeColor: string, race: string, experienceDescription: string, yearsOFExperience: number, specificity: string,  contactPhone: string, emailAddress: string, additionalInfo: string, sex: string) => {
    try {
        const response = await axios.post('https://api.bossteamaplikacija.rs/api/cast/edit', {
            id: id,
            type: type,
            fullName: fullName,
            dateOfBirth: dateOfBirth,
            nickname: nickname,
            jmbg: jmbg,
            address: address,
            city: city,
            height: height,
            weight: weight,
            hairColor: hairColor,
            hairSize: hairSize,
            eyeColor: eyeColor,
            race: race,
            experienceDescription: experienceDescription,
            yearsOFExperience: yearsOFExperience,
            specificity: specificity,
            contactPhone: contactPhone,
            emailAddress: emailAddress,
            additionalInfo: additionalInfo,
            sex: sex,
        })
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const addImage = async (id: number, names: Array<string>) => {
    try {
        const response = await axios.post('https://api.bossteamaplikacija.rs/api/cast/save-image-name', {
            id: id,
            names: names,
        })
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const getImages = async () => {
    try {
        const response = await axios.get('https://api.bossteamaplikacija.rs/api/cast/images/');
        return response;
    } catch (error) {
        console.log(error)
    }
};