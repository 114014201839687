import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Row, Col, Form, Input, Button, DatePicker, InputNumber, Select, Radio, message} from 'antd';
import moment from 'moment';
import {useStore} from '../../../store/root-store';
import styles from './styles.module.scss';
import {EditOutlined} from "@ant-design/icons";

const {TextArea} = Input;

interface Props { 
    id: number,
}

export const EditCastForm = observer(({id}: Props) => {

    const [success, setSuccess] = useState(false);
    const [castArray, setCastArray] = useState<Array<any>>([]);

    const {castStore: {createNewUser, cast, editCasts}} = useStore('');

    useEffect(() => {
        //@ts-ignore
        const castById: Array<any> = [...cast]?.filter(cast => cast?.id == id);
        setCastArray(castById);
    }, [id]);

    const onFinish = (values: any) => {
        editCasts(id, values.type, values.fullName, values.dateOfBirth, values.nickname, values.jmbg, values.address, values.city, values.height, values.weight, values.hairColor, values.hairSize, values.eyeColor, values.race, values.experienceDescription, values.yearsOfExperience, values.specificity, values.contactPhone, values.emailAddress, values.additionalInfo, values.sex);
        successMsg();
    };
    
    const onFinishFailed = (errorInfo: any) => {
        errorMsg();
   };

    const successMsg = () => {
        message.success('Izmene su sačuvane!', 3);
    };

    const errorMsg = () => {
        message.error('Molimo popunite sva obavezna polja, izmene nisu sačuvane!', 3);
    };
    
    const sexOptions = [
        {label: <span style={{color: '#152238'}}>Muški</span>, value: 'Muški'},
        {label: <span style={{color: '#152238'}}>Ženski</span>, value: 'Ženski'}
    ];

    const initialValues = {
        additionalInfo: cast.filter(data => data.id == id)[0]?.additionalInfo,
        address:cast.filter(data => data.id == id)[0]?.address,
        city:cast.filter(data => data.id == id)[0]?.city,
        contactPhone: cast.filter(data => data.id == id)[0]?.contactPhone,
        dateOfBirth: moment(cast.filter(data => data.id == id)[0]?.dateOfBirth),
        emailAddress:cast.filter(data => data.id == id)[0]?.emailAddress,
        experienceDescription:cast.filter(data => data.id == id)[0]?.experienceDescription,
        eyeColor:cast.filter(data => data.id == id)[0]?.eyeColor,
        fullName:cast.filter(data => data.id == id)[0]?.fullName,
        hairColor:cast.filter(data => data.id == id)[0]?.hairColor,
        hairSize:cast.filter(data => data.id == id)[0]?.hairSize,
        height:cast.filter(data => data.id == id)[0]?.height,
        jmbg:cast.filter(data => data.id == id)[0]?.jmbg,
        nickname:cast.filter(data => data.id == id)[0]?.nickname,
        race:cast.filter(data => data.id == id)[0]?.race,
        sex:cast.filter(data => data.id == id)[0]?.sex,
        specificity:cast.filter(data => data.id == id)[0]?.specificity,
        type:cast.filter(data => data.id == id)[0]?.type,
        weight:cast.filter(data => data.id == id)[0]?.weight,
        yearsOfExperience:cast.filter(data => data.id == id)[0]?.yearsOFExperience,
    };

    return (
        <div className={styles.formContainer}>
            <Row gutter={24}>
                <Col span={24} className={styles.title}>
                    <h1><EditOutlined /> Izmeni statistu</h1>
                    <span>polja oznacena zvezdicom su obavezna i potrebno je uneti neku vrednost</span>
                </Col>
                <Col span={24} style={{marginTop: '20px'}}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        // initialValues={{ remember: true }}
                        initialValues={initialValues}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                     <Form.Item
                        label="Kategorija"
                        name="type"
                        rules={[{ message: 'Kategorija je obavezna'}]}
                        initialValue={castArray[0]?.type}
                    >
                      <Select placeholder={castArray[0]?.type}>
                            <Select.Option value="Glumac">Glumac</Select.Option>
                            <Select.Option value="Dete glumac">Dete glumac</Select.Option>
                            <Select.Option value="Bend">Bend</Select.Option>
                            <Select.Option value="Muzicar">Muzicar</Select.Option>
                            <Select.Option value="Hostesa">Hostesa</Select.Option>
                            <Select.Option value="Glumac amater">Glumac amater</Select.Option>
                            <Select.Option value="Bilder">Bilderi</Select.Option>
                            <Select.Option value="Drugo">Drugo</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                        label="Ime i prezime"
                        name="fullName"
                        rules={[{required: true, message: 'Ime i prezime je obavezno' }]}
                        
                    >
                    <Input
                        placeholder={castArray[0]?.fullName}
                    />
                    </Form.Item>

                    <Form.Item
                        label='Pol'
                        name='sex'
                        rules={[{required: true, message: 'Pol je obevezan'}]}
                    >
                        <Radio.Group
                            options={sexOptions}
                            optionType='button'
                        />
                    </Form.Item>
                    <Form.Item
                        label="Kontakt telefon"
                        name="contactPhone"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Kontakt email adresa"
                        name="emailAddress"
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Datum rodjenja"
                        name="dateOfBirth"
                        rules={[{required: true, message: 'Datum rodjenja je obavezan'}]}
                    >
                    <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item
                        label="Nadimak"
                        name="nickname"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="JMBG"
                        name="jmbg"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>
                    <Form.Item
                        label="Adresa"
                        name="address"
                        rules={[{required: true, message: 'Adresa ja obavezna' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Grad"
                        name="city"
                        rules={[{required: true, message: 'Grad je obavezan' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Visina"
                        name="height"
                        initialValue={null}
                        rules={[{required: false}]}
                    >
                    <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="Tezina"
                        name="weight"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <InputNumber />
                    </Form.Item>
            
                    <Form.Item
                        label="Boja kose"
                        name="hairColor"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Duzina kose"
                        name="hairSize"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Boja ociju"
                        name="eyeColor"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Rasa"
                        name="race"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Opis iskustva"
                        name="experienceDescription"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <TextArea />
                    </Form.Item>

                    <Form.Item
                        label="Godine iskustva"
                        name="yearsOfExperience"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label="Specificnost"
                        name="specificity"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                    <TextArea />
                    </Form.Item>
            
                    <Form.Item
                        label="Dodatne informacije / opis"
                        name="additionalInfo"
                        initialValue={null}
                        rules={[{ required: false}]}
                    >
                        <TextArea />
                    </Form.Item>
            
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="default" htmlType="submit" className={styles.mainBtn}>
                        Izmeni
                    </Button>
                    </Form.Item>
                </Form>
                </Col>
            </Row>
        </div>
    )
});

export default EditCastForm;