import {types, flow, cast} from "mobx-state-tree";
import {getAllCast, addNewUser, filterCast, deleteCast, editCast} from "../../api/cast/api";
import moment from 'moment';

const castModel = types.model({
    id: types.maybeNull(types.number),
    type: types.maybeNull(types.string),
    fullName:types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    nickname: types.maybeNull(types.string),
    jmbg: types.maybeNull(types.string),
    address:types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    height: types.maybeNull(types.number),
    weight: types.maybeNull(types.number),
    hairColor: types.maybeNull(types.string),
    hairSize: types.maybeNull(types.string),
    eyeColor: types.maybeNull(types.string),
    race: types.maybeNull(types.string),
    experienceDescription: types.maybeNull(types.string),
    yearsOFExperience: types.maybeNull(types.number),
    specificity: types.maybeNull(types.string),
    contactPhone: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
    additionalInfo: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    sex: types.maybeNull(types.string),
});

// main model
export const CastStore = types.model({
    loading: types.boolean,
    count: types.maybeNull(types.number),
    cast: types.array(castModel),
    notificationMessage: types.string,
    lastInsertedId: types.maybeNull(types.number),
}).actions(self => {
    const getMaxValue = (field: string) => {
        // @ts-ignore: Unreachable code error
        const arrayOfValues = self.cast.map(data => data[field]);
        // @ts-ignore: Unreachable code error
        return Math.max(...arrayOfValues);
    };
    
    const getHighestDate = () => {
        //@ts-ignore
        const latestDate = new Date(Math.max(...self.cast.map(e => new Date(e.dateOfBirth))));
        return moment(latestDate).format('YYYY-MM-DD');
    };

    const getLowestDate = () => {
        //@ts-ignore
        const lowestDate = new Date(Math.min(...self.cast.map(e => new Date(e.dateOfBirth))));
        return moment(lowestDate).format('YYYY-MM-DD');
    };

    const getAllCasts = flow(function*() {
        try {
            self.loading = true;
            const response = yield getAllCast();
            if(response.data.cast) {
                self.count = response.data.count;
                console.log(response.data.cast)
                self.cast = cast(response?.data?.cast);
                self.notificationMessage = 'Ima rezultata';
            }
            self.loading = false;
        } catch (error) {
            self.loading = true;
        }
    });

    const createNewUser = flow(function*(type: string, fullName: string, dateOfBirth: string, nickname: string, jmbg: number, address: string, city: string, height: number, weight: number, hairColor: string, hairSize: string, eyeColor: string, race: string, experienceDescription: string, yearsOFExperience: number, specificity: string, contactPhone: string, emailAddress: string, additionalInfo: string, sex: string) {
        self.loading = true;
        try {
            const response = yield addNewUser(type, fullName, dateOfBirth, nickname, jmbg, address, city, height, weight, hairColor, hairSize, eyeColor, race, experienceDescription, yearsOFExperience, specificity, contactPhone, emailAddress, additionalInfo, sex);
            if(response) {
                self.cast.push(response?.data?.cast)
                self.lastInsertedId = self.cast[self.cast.length - 1].id;
            }
            self.loading = false;
        } catch (e) {
            self.loading = true;
        }
    });

    const editCasts = flow(function*(id: number, type: string, fullName: string, dateOfBirth: string, nickname: string, jmbg: number, address: string, city: string, height: number, weight: number, hairColor: string, hairSize: string, eyeColor: string, race: string, experienceDescription: string, yearsOFExperience: number, specificity: string, contactPhone: string, emailAddress: string, additionalInfo: string, sex: string) {
        self.loading = true;
        try {
            const response = yield editCast(id, type, fullName, dateOfBirth, nickname, jmbg, address, city, height, weight, hairColor, hairSize, eyeColor, race, experienceDescription, yearsOFExperience, specificity, contactPhone, emailAddress, additionalInfo, sex);
            if(response?.data?.cast) {
                self.cast = cast(response?.data?.cast)
            }
            self.loading = false;
        } catch (e) {
            self.loading = true;
        }
    });

    const filterCasts = flow(function*(dateFilterFrom, dateFilterTo, heightFilterFrom, heightFilterTo, weightFilterFrom, weightFilterTo, yearsOfExperienceFilterFrom, yearsOfExperienceFilterTo) {
        self.loading = true;
        try {
            const response = yield filterCast(dateFilterFrom, dateFilterTo, heightFilterFrom, heightFilterTo, weightFilterFrom, weightFilterTo, yearsOfExperienceFilterFrom, yearsOfExperienceFilterTo);
            if(response?.data?.cast?.length > 0) {
                self.cast = cast(response.data.cast);
                self.notificationMessage = 'Ima rezultata';
            } else {
                self.notificationMessage = 'Nema rezultata';
            }
            self.loading = false;
        } catch (e) {
            console.log(e);
            self.loading = true;
        }
    });

    const deleteCasts = flow(function*(id) {
        self.loading = true;
        try {
            const response = yield deleteCast(id);
            if(response?.data?.cast) {
                self.cast = cast(response.data.cast);
            } else {
                self.notificationMessage = 'Nema rezultata';
            }
            self.loading = false;
        } catch (e) {
            self.loading = true;
            console.log(e);
        }
    })

    return {getAllCasts, createNewUser, getMaxValue, getHighestDate, getLowestDate, filterCasts, deleteCasts, editCasts}
});

export const initCastStore = () => {
    return CastStore.create({
        loading: true,
        count: null,
        notificationMessage: '',
        lastInsertedId: null,
        cast: [{
            id: null,
            type: '',
            fullName:'',
            dateOfBirth: '',
            nickname: '',
            jmbg: '',
            address:'',
            city: '',
            height: null,
            weight: null,
            hairColor: '',
            hairSize: '',
            eyeColor: '',
            race: '',
            experienceDescription: '',
            yearsOFExperience: null,
            specificity: '',
            contactPhone: '',
            emailAddress: '',
            additionalInfo: '',
            sex: '',
        }]
    });
};