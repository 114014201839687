import makeInspectable from 'mobx-devtools-mst';

import {CastStore, initCastStore} from '../store/cast';
import {ImagesStore, initImagesStore} from './images';
import {useMemo} from 'react';
import {Instance, types, applySnapshot} from 'mobx-state-tree';
// import {getSingleUrlParam} from '@/utils/url';
import 'mobx-react-lite/batchingForReactDom';

import {configure} from 'mobx';

configure({
  useProxies: 'never',
});

const RootStore = types.model({
  castStore: CastStore,
  imagesStore: ImagesStore,
});

export type RootStoreType = Instance<typeof RootStore>;

let store: RootStoreType;

export function initializeStore(snapshot = null) {
  // const isSsr = typeof window === 'undefined';
  // const domain = isSsr ? '' : getSingleUrlParam('q') ?? '';
  const _store =
    store ??
    RootStore.create({
      castStore: initCastStore(),
      imagesStore: initImagesStore(),
    });
  if (snapshot) {
    applySnapshot(_store, snapshot);
  }
  if (typeof window === 'undefined') {
    return _store;
  }
  if (!store) {
    store = _store;
  }
  makeInspectable(store);
  return store;
}

export function useStore(initialState: any): Instance<typeof RootStore> {
  return useMemo(() => initializeStore(initialState), [initialState]);
}