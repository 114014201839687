import React, {useState} from 'react';
import {Layout, Tabs, Row, Col, Input, Button, message} from 'antd';
import {ExtrasTable} from './extrasTable';
import {NewCastForm} from './newCastForm';
import styles from './styles.module.scss';

// const {Sider} = Layout;
const {TabPane} = Tabs;

export const Dashboard: React.FC = () => {
    const [password, setPassword] = useState('');
    const [isCorrectPassword, setIsCorrectPassword] = useState(false);

    const handleLogin = () => {
        if(password === '715126') {
            setIsCorrectPassword(true);
        } else {
            errorMsg();
        }
    };

    const errorMsg = () => {
        message.error('Lozinka nije ispravna!', 3);
    };

    return (
        <Layout className={styles.container}>
            <Row gutter={24} style={{marginRight: 0}}>
                    {isCorrectPassword ? 
                <Col span={24} style={{paddingRight: 0}}>
                        <Tabs defaultActiveKey={'1'} tabPosition={'top'} className={styles.tabs} tabBarStyle={{color: '#fff', padding: '10px 20px 0 20px'}}>
                            <TabPane tab={'Svi statisti'} key={'1'}>
                                <ExtrasTable />
                            </TabPane>
                            <TabPane tab={'Dodaj nove statiste'} key={'2'}>
                                <NewCastForm />
                            </TabPane>
                        </Tabs> 
                            
                </Col>
                        :
                        <Col span={24}>
                            <div className={styles.login}>
                            <img src='/logo.jpeg' />
                            
                                <div className={styles.inputs}>
                                    <Input.Password onChange={(e) => setPassword(e.target.value)} style={{width: '200px'}} placeholder='Pristupna lozinka' />
                                    <Button onClick={handleLogin} className={styles.mainBtn}>Uloguj se</Button>
                                </div>
                            </div>    
                          </Col>
                    }
            </Row>
        </Layout>
    );
};

export default Dashboard;